import React, { Component, Fragment } from 'react';
import Profile from './Profile';
import Header from './Header';
import './HomePage.css';
import Publications from './Publications';
import Posts from './Posts';
import ContactMe from './ContactMe';
import AliceImage from './photos/Alice2.png'

class NewHomePage extends Component {

  render() {
    return (
      <div className="NewHomePage">
        <div className='picture-container'>
          <img className='picture' src={AliceImage} />
        </div>
        <div className='menu-name'> 
          Alice Lucas 
        </div>
        <div className='menu-name'> 
          alicelucas2015@u.northwestern.edu
          <hr style={{border: "1px solid lightblue"}} />
        </div>
        <div className='profile-right'>
          <p style={{fontSize: '22px'}}>  About Me </p>
                 <p style={{fontSize: '16px'}}>  I am a curious and enthusiastic French-American graduate student researcher
                 studying at the Image and Video Processing Laboratory at Northwestern University. 
                 In 2011, I moved from Paris to pursue a Bachelor of Science degree at the University of Wisconsin-Madison, 
                 where I majored in a multi-disciplinary diploma named Applied Math, Engineering and Physics (AMEP). I also minored
                 in Computer Science. Majoring in AMEP allowed me to obtain a solid foundation in interdisciplinary sciences
                  -- I highly recommend it to anyone currently at UW. In 2015, I graduated from UW-Madison and went 
                  to Northwestern to pusue a PhD in Electrical Engineering and Computer Science. At Northwestern, I joined the Image
                   and Video Processing Laboratory (IVPL) where I developed new Deep Learning-based methods for Image Processing
                  algorithms. 
                   </p>        
          </div>

          <div className='profile-right'>
            <p style={{fontSize: '22px'}}>  Research Experience </p>
                  <p style={{fontSize: '16px'}}> My research interests lie at the intersection of Image Processing analytical methods
                   and Machine Learning research. My current research at the IVPL is centered on the use of 
                  deep learning models for various image processing tasks. </p>
                  <p style={{fontSize: '16px'}}> In 2015-2016, I performed research on neural encoding of S1 in the brain of monkeys
                  , a project is in collaboration with the Rehabilitation Institute of Chicago (RIC). The ultimate goal 
                  of this project is to analyze the models learned by our machine learning methods to better understand 
                  the sensory nervous system. To this end, I trained several type of deep neural networks (MLP, CNN, RNN) in Keras to learn the mapping between
                  limb kinematics and corresponding neural activations in the brain. </p>        
                  <p style={{fontSize: '16px'}}> From 2016, I implemented  deep neural networks (CNNs and GANs) in Pytorch as 
                  part of a super-resolution framework for improving the quality of very high resolution (4K) videos. This project involved
                  multiple sub-projects, from the use of deep perceptual losses, spatially adaptive losses, and affine projections 
                  (see "Publications" section for more info!).
                  This project was supported and funded by the Sony 2017 Research Award Program and was extended for an additional year in 2018. </p>
          </div>



          <div className='profile-right'>
            <p style={{fontSize: '22px'}}>  Work Experience </p>
                  <p style={{fontSize: '16px'}}> During my graduate program, I have participated in two summer internships -- one at Cadre Research Labs in Chicago
                   in 2016, and more recently at Two Six Labs in Washington D.C. in 2018. At Cadre Research, I worked on implementing a software 
                   for denoising 3D scans of cartridge casings, in addition to experimenting with deep CNNs for automatic segmentations 
                   of such scans. At Two Six, I worked on the implementation of a source code summarization model based on encoder-decoder
                   tree-RNNs. </p>        
          </div>

          <div className='profile-right'>
            <p style={{fontSize: '22px'}}>  Publications </p>
              <ul>
                <li style={{fontSize: '16px'}}> Lucas, A., Lopez-Tapia, S., Molina, R., and Katsaggelos, A. K. (2019). 
                    Generative Adversarial Networks and Perceptual Losses for Video Super-Resolution.
                    <i> IEEE transactions on image processing: a publication of the IEEE Signal Processing Society (2019)</i>. </li>
                <li style={{fontSize: '16px'}}>Wang, X., Lucas, A., Lopez-Tapia, S., Wu, X., Molina, R., and Katsaggelos, A. K. 
                Spatially Adaptive Losses for Video Super-Resolution with GANs.
                <i> 2019 IEEE International Conference on Acoustics, Speech, and Signal Processing (ICASSP).</i> </li>
                <li style={{fontSize: '16px'}}>Lucas, A. Tomlinson, T., Rohani, N., Chowdhury, R. H., Solla, S. A., Katsaggelos,
                 A. K., and Miller, L. E (2019). Deep Neural Networks for Modeling Neural Spiking in S1 Cortex. 
                 <i> Frontiers in Systems Neuroscience.</i> </li>
                <li style={{fontSize: '16px'}}> Lucas, A., Lopez-Tapia S., Molina, R., and Katsaggelos, A. K. (2018). 
                    Generative Adversarial Networks and Perceptual Losses For Video Super-Resolution. 
                  <i> 2018 IEEE International Conference on Image Processing.</i></li>
                <li style={{fontSize: '16px'}}>Lucas, A., Iliadis, M., Molina, R., and Katsaggelos, A. K. (2018). 
                Using Deep Neural Networks for Inverse Problems in Imaging: Beyond Analytical Methods.
                <i> IEEE Signal Processing Magazine, 35(1), 20-36.</i></li>

              </ul>
          </div>





     </div>


    );
  }
}

export default NewHomePage;
