import React, { Component, Fragment } from 'react';
import Menu from './Profile';
import './Header.css';

class Header extends Component {
  render() {
    return (
      <div className='header'>

        <div className='header-items' onClick={(event) => { this.props.handleClick('Home') }}>Home</div>
        <div className='header-items' onClick={(event) => { this.props.handleClick('Publications') }}>Publications</div>
        <div className='header-items' onClick={(event) => { this.props.handleClick('Posts') }}>Posts</div>
        
        
      </div>
    )
  }
}

export default Header;

