import React, { Component, Fragment } from 'react';
import Menu from './Profile';

class ContactMe extends Component {
  render() {
    return (
   <span>Contact me!</span>
    )
  }
}
export default ContactMe;

