import React, { Component, Fragment } from 'react';
import Menu from './Profile';

class Posts extends Component {
  render() {
    return (
   <span>posts</span>
    )
  }
}

export default Posts;

