import React, { Component, Fragment } from 'react';
import AliceImage from './photos/Alice2.png'
import './Profile.css';
import Header from './Header'

class Menu extends Component {
  render() {
      return (
        <div className='profile-container'>
          <div className='profile-left'>
            <div className='profile-image-container'>
              <img className='menu-image' src={AliceImage} />
            </div>
              <span className='menu-name'>
              Alice Lucas
              </span>
              <span className='menu-name'> 
              alicelucas2015@u.northwestern.edu
              </span>
          </div>
          <div className='profile-right'>
          <p style={{fontSize: '60px'}}>  About Me </p>
                 <p style={{fontSize: '35px'}}>  My science career started at the University of Wisconsin-Madison in 2011, where I majored in Applied Math, 
                   Engineering and Physics (AMEP), in addition to minoring in Computer Science. Majoring in AMEP allowed me to 
                   obtain a solid foundation in interdisciplinary sciences -- I highly recommend it to anyone currently at UW. 
                   In 2015, I graduated from UW-Madison and went to Northwestern as a PhD student. My work there consists of applying
                   deep neural networks to the problem of video super-resolution. The main challenges that we are currently working on
                   is that of merging the learning approach of DNNs with the analytical solutions used by the image processing community. 
                   During my graduate program, I have participated in two summer internships -- one at Cadre Research Labs in Chicago
                   in 2016, and more recently at Two Six Labs in 2018. At Cadre Research, I worked on implementing a software 
                   for denoising 3D scans of cartridge casings, in addition to experimenting with deep CNNs for automatic segmentations 
                   of such scans. At Two Six, I worked on the implementation of a source code summarization model based on encoder-decoder
                   tree-RNNs. </p>        
          </div>
        </div>
      )
  }
}

export default Menu;
