import React, { Component, Fragment } from 'react';
import Profile from './Profile';
import Header from './Header';
import './HomePage.css';
import Publications from './Publications';
import Posts from './Posts';
import ContactMe from './ContactMe';

class HomePage extends Component {

  constructor(props) {
    super(props);

    this.state = { displayPage: 'Profile' };

    this.visibleComponent = <Profile />
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(nextPage){
    console.log("Something")
    
    switch (nextPage) {
      case 'Publications':
        this.visibleComponent = <Publications/>
        break;
      case 'Posts':
        this.visibleComponent = <Posts/>
        break;
      case 'ContactMe':
        this.visibleComponent = <ContactMe/>
        break;
      case 'Home':
        this.visibleComponent= <Profile/>
        break;
    
      default:
        break;
    }

    this.setState({
      displayPage: nextPage
    })

  }

  render() {
    console.log(this.state.displayPage)
    return (
      <div style={{height:'100%'}}>
        <Header handleClick={this.handleClick} />
        {this.visibleComponent}
      </div>
    
    )
  }
}

export default HomePage;

