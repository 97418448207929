import React, { Component, Fragment } from 'react';
import Menu from './Profile';
import './Publications.css';

class Publications extends Component {
  render() {
    return ( 
      <div className = 'paragraph'>
      <p style={{fontSize: '60px'}}>  Publications</p>
        <p style={{fontSize: '35px'}}> Lucas, A., Iliadis, M., Molina, R., and Katsaggelos, A. K. (2018). 
          <i> Using Deep Neural Networks for Inverse Problems in Imaging: Beyond Analytical Methods. </i>  
           IEEE Signal Processing Magazine, 35(1), 20-36.</p>
        <p style={{fontSize: '35px'}}> Lucas, A., Lopez-Tapia S., Molina, R., and Katsaggelos, A. K. (2018). 
          <i> Generative Adversarial Networks and Perceptual Losses For Video Super-Resolution</i>. 
         2018 IEEE International Conference on Image Processing.</p>
        <p style={{fontSize: '35px'}}>Lucas, A., Tapia, S. L., Molina, R., and Katsaggelos, A. K. (2018). 
          <i> Generative Adversarial Networks and Perceptual Losses for Video Super-Resolution</i>.
           arXiv preprint arXiv:1806.05764.</p>
      </div>

    )
  }
}

export default Publications;

